<template>
  <div class="flex flex-wrap gap-x-[10px] gap-y-1 font-medium" :class="{ 'leading-none': showUserDiscount }">
    <div v-if="showUserDiscount" class="text-[--color-neutral-a2] line-through">
      {{ formatPriceForCurrency(listWithTax) }}
    </div>

    <div class="flex flex-wrap gap-x-2 gap-y-1">
      <VcPriceDisplay
        v-if="shouldUseActualPrice(value?.list, value?.actual)"
        class="font-bold"
        :class="priceColorClass"
        :formatted-value="formatPriceForCurrency(useCnyExtraTaxPrice ? actualWithTax : value?.actual)"
      />
      <VcPriceDisplay
        v-else
        class="font-bold"
        :formatted-value="formatPriceForCurrency(useCnyExtraTaxPrice ? listWithTax : value?.list)"
      />

      <div v-if="showUserDiscount" class="ml-[-2px]">({{ userDiscountPercentage }}% Discount)</div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useCurrency } from "@/core/composables";
import { shouldUseActualPrice } from "@/ui-kit/utilities/price";
import type { MoneyType, PriceType } from "@/core/api/graphql/types";

interface IProps {
  value?: PriceType | { list: MoneyType; actual: MoneyType };
  priceColorClass?: string;
  userDiscountPercent?: number | undefined;
  listWithTax?: MoneyType;
  actualWithTax?: MoneyType;
}
const props = withDefaults(defineProps<IProps>(), {
  priceColorClass: "",
});

const { currentCurrency } = useCurrency();

const useCnyExtraTaxPrice = computed(
  () =>
    currentCurrency.value.code === "CNY" &&
    !!props.listWithTax &&
    !!props.value &&
    props.listWithTax.amount > props.value.actual.amount,
);
const showUserDiscount = computed(() => props.userDiscountPercent && props.userDiscountPercent > 0);
const userDiscountPercentage = computed(() =>
  props.userDiscountPercent ? props.userDiscountPercent * 100 : undefined,
);

function formatPriceForCurrency(value: MoneyType | undefined): string {
  if (!value) {
    return "N/A";
  }

  const amount = value.amount;

  switch (currentCurrency.value.code) {
    case "USD":
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(amount);

    case "EUR":
      return new Intl.NumberFormat("de-DE", {
        style: "currency",
        currency: "EUR",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(amount);

    case "GBP":
      return new Intl.NumberFormat("en-GB", {
        style: "currency",
        currency: "GBP",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(amount);

    case "SEK": {
      const formattedSEK = new Intl.NumberFormat("sv-SE", {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })
        .format(amount)
        .replace(/\s/g, " ");

      return `kr ${formattedSEK}`;
    }

    case "CNY":
      return new Intl.NumberFormat("zh-CN", {
        style: "currency",
        currency: "CNY",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(amount);

    case "JPY":
      return new Intl.NumberFormat("ja-JP", {
        style: "currency",
        currency: "JPY",
        minimumFractionDigits: 0, // No cents for JPY
        maximumFractionDigits: 0,
      }).format(amount);

    case "BRL":
      return new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(amount);

    default:
      return amount.toString(); // Fallback if the currency isn't listed
  }
}
</script>
