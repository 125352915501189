<template>
  <nav class="vc-breadcrumbs">
    <ol ref="list" class="vc-breadcrumbs__list" @scroll="checkScroll">
      <li v-if="!hideHomeIcon" class="mr-2 hidden md:flex print:flex">
        <router-link to="/" class="vc-breadcrumbs__link">
          <VcIcon name="home-alt" :size="16" class="vc-breadcrumbs__icon" />
        </router-link>
      </li>
      <template v-for="(item, i) in items" :key="i">
        <template v-if="i < items.length - 1">
          <li class="vc-breadcrumbs__item">
            <component :is="item.route ? 'router-link' : 'span'" :to="item.route" class="vc-breadcrumbs__link">
              {{ item.title }}
            </component>
          </li>

          <li class="vc-breadcrumbs__item">
            <span class="vc-breadcrumbs__slash">/</span>
          </li>
        </template>

        <!-- Last breadcrumbs item -->
        <li v-else class="vc-breadcrumbs__item">{{ item.title }}</li>
      </template>
    </ol>
  </nav>
</template>

<script setup lang="ts">
import { defineProps, ref, onMounted, onUpdated, onUnmounted } from "vue";
interface IProps {
  items: IBreadcrumb[];
  hideHomeIcon?: boolean;
}

defineProps<IProps>();

const list = ref<HTMLElement | null>(null);

const checkScroll = () => {
  if (list.value) {
    if (list.value.scrollWidth - list.value.scrollLeft === list.value.clientWidth) {
      list.value.classList.add("scrolled-to-end");
    } else {
      list.value.classList.remove("scrolled-to-end");
    }

    if (list.value.scrollLeft === 0) {
      list.value.classList.add("scrolled-from-start");
    } else {
      list.value.classList.remove("scrolled-from-start");
    }
  }
};

onMounted(() => {
  if (list.value) {
    list.value.classList.add("scrolled-from-start");
    list.value.addEventListener("scroll", checkScroll);
  }
});

onUpdated(checkScroll);

onUnmounted(() => {
  if (list.value) {
    (list.value as HTMLElement).removeEventListener("scroll", checkScroll);
  }
});
</script>

<style lang="scss">
.vc-breadcrumbs {
  @apply relative;

  &__list {
    @apply flex flex-wrap text-sm font-medium;

    @media (max-width: theme("screens.md")) {
      &.scrolled-to-end::after {
        display: none;
      }

      &.scrolled-from-start::before {
        display: none;
      }

      @apply flex flex-nowrap overflow-x-auto w-full;

      &::after {
        @apply absolute top-0 content-[''] right-[-3px] bottom-0 w-12 bg-gradient-to-r from-transparent to-white pointer-events-none;
      }

      &::before {
        @apply absolute top-0 content-[''] left-[-3px] bottom-0 w-12 bg-gradient-to-l from-transparent to-white pointer-events-none;
      }

      &::-webkit-scrollbar {
        @apply h-2 rounded-xl bg-white;
      }

      &::-webkit-scrollbar-thumb {
        @apply bg-[color:var(--color-neutral-a4)] rounded-xl;
      }

      &::-webkit-scrollbar-track {
        @apply rounded-xl;
      }
    }
  }

  &__item {
    @apply text-[--color-neutral-a1] mb-[7px];

    @media (max-width: theme("screens.md")) {
      @apply whitespace-nowrap;
    }
  }

  &__link {
    @apply text-[--color-link];

    &:hover {
      @apply text-[--color-link-hover];
    }
  }

  &__slash {
    @apply mx-2 text-[20px];
  }

  @-moz-document url-prefix() {
    scrollbar-width: thin;
  }
}
</style>
