<template>
  <!-- CALIBRATED ITEM INPUTS -->
  <div v-if="isCalibrated" class="add-to-cart__calibrated grid auto-cols-[max-content_auto] gap-y-5 print:hidden">
    <VcInput
      v-model="itemNumber"
      :label="$t('shared.product.calibrated_item.item_number_input_label')"
      :placeholder="$t('shared.product.calibrated_item.item_number_input_placeholder')"
      :message="itemNumberErrors"
      :error="!!itemNumberErrors"
      name="itemNumber"
      input-class="grid grid-cols-[subgrid] col-span-2 w-full"
    />
    <VcInput
      v-model="serialNumber"
      :label="$t('shared.product.calibrated_item.serial_number_input_label')"
      :placeholder="$t('shared.product.calibrated_item.serial_number_input_placeholder')"
      :message="serialNumberErrors"
      :error="!!serialNumberErrors"
      name="serialNumber"
      input-class="grid grid-cols-[subgrid] col-span-2"
    />
  </div>
  <!-- VOLUME PRICING -->
  <div v-if="isVolumePricing" class="tl-table tl-table--data border-0">
    <table>
      <thead class="!bg-transparent">
        <tr>
          <th class="text-left">{{ $t("shared.product.volume_pricing.quantity_table_header") }}</th>
          <th class="text-right">
            {{ $t("shared.product.volume_pricing.price_table_header", [measureUnit])
            }}{{ currentCurrency.code === "CNY" ? $t("shared.product.price_with_tax") : "" }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in product.price.tierPrices" :key="index">
          <td class="text-left">{{ getQuantityRange(index) }}</td>
          <td class="text-right">{{ item.price.formattedAmount }}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <!-- PER ITEM NOTE SECTION -->
  <div v-if="perItemNote">
    <p>
      {{ perItemNote }}
    </p>
  </div>
  <!-- REST OF THE ORIGINAL PRICING LOGIC -->
  <div class="relative flex gap-6 print:hidden">
    <VcInput
      v-model.number="enteredQuantity"
      type="number"
      :aria-label="$t('common.labels.product_quantity')"
      :disabled="disabled"
      :max="maxQty"
      :min="minQty"
      :error="!!errorMessage"
      :message="errorMessage"
      single-line-message
      center
      show-empty-details
      select-on-click
      size="lg"
      :label="$t('Qty:')"
      class="add-to-cart"
      :class="{ hidden: hideQuantity }"
      @input="onInput"
      @keypress="onKeypress"
      @blur="onBlur"
    >
    </VcInput>
    <VcButton
      color="primary"
      size="base"
      variant="solid"
      :loading="loading"
      :disabled="disabled || !!errorMessage"
      :title="buttonText"
      truncate
      class="add-to-cart__button flex-1 py-[11px] font-bold"
      @click="onChange"
    >
      <template v-if="buttonText === t('shared.cart.added_to_cart')" #prepend>
        <VcIcon name="check-circle-alt" :size="20" class="mr-2" />
      </template>
      {{ buttonText }}
    </VcButton>
  </div>
</template>

<script setup lang="ts">
import { toTypedSchema } from "@vee-validate/yup";
import { clone } from "lodash";
import { useField } from "vee-validate";
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useCurrency, useErrorsTranslator, useGoogleAnalytics } from "@/core/composables";
import { LINE_ITEM_QUANTITY_LIMIT } from "@/core/constants";
import { ValidationErrorObjectType } from "@/core/enums";
import { Logger } from "@/core/utilities";
import { useShortCart } from "@/shared/cart/composables";
import { useNotifications } from "@/shared/notification";
import { useQuantityValidationSchema } from "@/ui-kit/composables";
import type {
  Product,
  ShortCartFragment,
  ShortLineItemFragment,
  VariationTypeExtension,
} from "@/core/api/graphql/types";
import type { NamedValue } from "vue-i18n";

const emit = defineEmits<IEmits>();

const props = defineProps<IProps>();

const notifications = useNotifications();

const { currentCurrency } = useCurrency();

const itemNumber = ref("");
const itemNumberErrors = ref("");
const serialNumber = ref("");
const serialNumberErrors = ref("");

interface IEmits {
  (event: "update:lineItem", lineItem: ShortLineItemFragment): void;
  (event: "update:enteredQuantity", enteredQuantity: number): void;
}

interface IProps {
  product: Product | VariationTypeExtension;
  reservedSpace?: boolean;
  hasVariations?: boolean;
  isCalibrated?: boolean;
  isVolumePricing?: boolean;
  hideQuantity?: boolean;
}

const isInStock = computed(
  () => props.product.availabilityData?.isInStock && props.product.availabilityData?.isBuyable,
);
const availableQuantity = computed(() => props.product.availabilityData?.availableQuantity);
const minQuantity = computed(() => props.product.minQuantity);
const maxQuantity = computed(() => props.product.maxQuantity);
const bypassMaxQuantity = ref(true);

const { cart, addToCart, changeItemQuantity } = useShortCart();
const { t } = useI18n();
const ga = useGoogleAnalytics();
const { getTranslation } = useErrorsTranslator("validation_error");
const { quantitySchema } = useQuantityValidationSchema({
  isInStock,
  availableQuantity,
  minQuantity,
  maxQuantity,
  bypassMaxQuantity,
});

const loading = ref(false);
const variationAdded = ref(false);
const addedToCart = ref(false);

const countInCart = computed<number>(() => getLineItem(cart.value?.items)?.quantity || 0);
const minQty = computed<number>(() => minQuantity.value || 1);
const maxQty = computed<number>(() => LINE_ITEM_QUANTITY_LIMIT);

const disabled = computed<boolean>(() => loading.value || !props.product.availabilityData?.isAvailable);

const buttonText = computed<string>(() =>
  addedToCart.value ? t("shared.cart.added_to_cart") : t("common.buttons.add_to_cart"),
);

const rules = computed(() => toTypedSchema(quantitySchema.value));

const measureUnit = computed(() => {
  if ((props.product as Product).measureUnit?.toLocaleLowerCase() === "each") {
    return t("shared.product.volume_pricing.unit_title");
  } else if ((props.product as Product).measureUnit?.toLocaleLowerCase() === "meter") {
    return t("shared.product.volume_pricing.meter_title");
  }

  return t("shared.product.volume_pricing.unit_title");
});

const perItemNote = computed(
  () => (props.product as Product).descriptions.find((d) => d.reviewType === "PerItemNote")?.content,
);

const enteredQuantity = ref(!disabled.value ? minQty.value : undefined);

const { errorMessage, validate, setValue } = useField("quantity", rules, {
  initialValue: enteredQuantity,
  validateOnMount: true,
});

/**
 * Process button click to add/update cart line item.
 */
async function onChange() {
  if (addedToCart.value) {
    return;
  }

  if (props.isCalibrated) {
    const calibrationFormValid = validateFormOnCalibrated();

    if (!calibrationFormValid) {
      return;
    }
  }

  const { valid } = await validate();

  if (!valid || disabled.value) {
    return;
  }

  addedToCart.value = false;
  loading.value = true;

  let lineItem = getLineItem(cart.value?.items);

  let updatedCart: ShortCartFragment | undefined;

  const isAlreadyExistsInTheCart = !!lineItem;
  if (isAlreadyExistsInTheCart) {
    const quantityToAdd = enteredQuantity.value || 0;
    updatedCart = await changeItemQuantity(
      lineItem!.id,
      lineItem?.quantity ? lineItem?.quantity + quantityToAdd : quantityToAdd,
    );
  } else {
    const inputQuantity = enteredQuantity.value || minQty.value;

    updatedCart = await addToCart(props.product.id!, inputQuantity);

    /**
     * Send Google Analytics event for an item added to cart.
     */
    ga.addItemToCart(props.product, inputQuantity);
  }

  lineItem = clone(getLineItem(updatedCart?.items));

  if (!lineItem) {
    Logger.error(onChange.name, 'The variable "lineItem" must be defined');
    notifications.error({
      text: t(
        isAlreadyExistsInTheCart
          ? "common.messages.fail_to_change_quantity_in_cart"
          : "common.messages.fail_add_product_to_cart",
        {
          reason: updatedCart?.validationErrors
            ?.filter(
              (validationError) =>
                validationError.objectId === props.product.id &&
                validationError.objectType === ValidationErrorObjectType.CatalogProduct,
            )
            .map((el) => {
              return getTranslation({
                code: el.errorCode,
                parameters: el.errorParameters?.reduce((acc, err) => {
                  acc[err.key] = err.value;
                  return acc;
                }, {} as NamedValue),
                description: el.errorMessage,
              });
            })
            .join(" "),
        },
      ),
      duration: 4000,
      single: true,
    });
  } else {
    emit("update:lineItem", lineItem);
  }

  loading.value = false;
  addedToCart.value = true;

  if (props.hasVariations) {
    variationAdded.value = true;
  }

  setTimeout(() => {
    addedToCart.value = false;
  }, 2000);
}

function getLineItem(items?: ShortLineItemFragment[]): ShortLineItemFragment | undefined {
  return items?.find((item) => item.productId === props.product.id);
}

/**
 * Ignore non-numeric keys.
 */
function onKeypress(event: KeyboardEvent) {
  if (!/[0-9]/.test(event.key)) {
    event.preventDefault();
  }
}

/**
 * Limit max value.
 */
function onInput() {
  if (!enteredQuantity.value) {
    enteredQuantity.value = undefined;
  } else if (enteredQuantity.value > LINE_ITEM_QUANTITY_LIMIT) {
    enteredQuantity.value = LINE_ITEM_QUANTITY_LIMIT;
  } else {
    setValue(enteredQuantity.value);
  }
}

function onBlur() {
  if (!enteredQuantity.value || enteredQuantity.value < 1) {
    enteredQuantity.value = countInCart.value || minQty.value;
  }

  emit("update:enteredQuantity", enteredQuantity.value);
}

function validateFormOnCalibrated() {
  itemNumberErrors.value = "";
  serialNumberErrors.value = "";

  let isValid = true;

  if (!props.isCalibrated) {
    return isValid;
  }

  if (!itemNumber.value) {
    itemNumberErrors.value = t("shared.product.calibrated_item.input_error_label");
    isValid = false;
  }

  if (!serialNumber.value) {
    serialNumberErrors.value = t("shared.product.calibrated_item.input_error_label");
    isValid = false;
  }

  return isValid;
}

function getQuantityRange(index: number) {
  const tierPrices = props.product.price.tierPrices;
  if (index === 0) {
    return `${tierPrices[index].quantity} ${t("shared.product.calibrated_item.table_to")} ${tierPrices[index + 1].quantity - 1}`;
  } else if (index === tierPrices.length - 1) {
    return `${tierPrices[index].quantity} ${t("shared.product.calibrated_item.or_more")}`;
  } else {
    return `${tierPrices[index].quantity} ${t("shared.product.calibrated_item.table_to")} ${tierPrices[index + 1].quantity - 1}`;
  }
}
</script>

<style lang="scss">
.add-to-cart {
  .vc-line-item__slot:has(&, * &) {
    @apply w-[13rem];

    @container (width > theme("containers.2xl")) {
      @apply w-[15.7rem];
    }
  }

  &.vc-input {
    @apply flex-row gap-3 items-center;
  }

  .vc-input {
    &__input {
      @apply max-w-16 px-1 min-w-16;
    }
  }

  .vc-input-details {
    @apply absolute top-[calc(100%+5px)] left-0;
  }

  &__calibrated {
    .vc-label {
      @apply pt-3;
    }
  }

  &__button {
    --vc-button-min-width: 160px;
  }
}
</style>
