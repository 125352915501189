<template>
  <div :id="componentId" class="image-gallery-zoomed">
    <div class="image-gallery-zoomed__images-container">
      <template v-if="images?.length">
        <Swiper
          class="image-gallery-zoomed__images"
          :modules="modules"
          :thumbs="{ swiper: thumbsSwiper }"
          :zoom="zoomOptions"
          :keyboard="{
            enabled: true,
          }"
          @swiper="setImagesSwiper"
          @after-init="checkActiveImageSize"
          @slide-change="checkActiveImageSize"
        >
          <SwiperSlide v-for="(image, i) in images" :key="image.url || i" zoom>
            <VcImage
              class="image-gallery-zoomed__img"
              :src="image.url"
              :alt="image.name"
              :data-te-img="image.url"
              :lazy="i === 0 ? false : true"
            />
            <!-- <div v-if="image.name" class="image-gallery-zoomed__img-caption">
              Lorem Ipsum is simply dummy text of the printing industry
            </div> -->
          </SwiperSlide>
        </Swiper>
        <button
          type="button"
          class="image-gallery-zoomed__button"
          :class="{ 'image-gallery-zoomed__button--hidden': hideZoomBtn }"
          :aria-label="
            isZoomed
              ? $t('shared.catalog.product_details.product_image_gallery.zoom_out')
              : $t('shared.catalog.product_details.product_image_gallery.zoom_in')
          "
          :title="
            isZoomed
              ? $t('shared.catalog.product_details.product_image_gallery.zoom_out')
              : $t('shared.catalog.product_details.product_image_gallery.zoom_in')
          "
          @click="toggleZoom"
          @keydown="handleToggleZoom"
        >
          <VcIcon v-if="!isZoomed" class="image-gallery-zoomed__icon" name="zoom-in" size="xs" aria-hidden="true" />
          <VcIcon v-else class="image-gallery-zoomed__icon" name="zoom-out" size="xs" aria-hidden="true" />
          <span>{{
            isDesktop
              ? $t("shared.catalog.product_details.product_image_gallery.double_click_to_zoom")
              : $t("shared.catalog.product_details.product_image_gallery.pinch_to_zoom")
          }}</span>
        </button>
      </template>
      <!-- no-image -->
      <VcImage v-else class="image-gallery-zoomed__img" />
    </div>

    <div v-if="images?.length > 1" class="image-gallery-zoomed__thumbs-container">
      <Swiper
        class="image-gallery-zoomed__thumbs"
        :slides-per-view="THUMBS_PER_VIEW"
        :slides-per-group="THUMBS_PER_VIEW - 1"
        :slides-offset-after="0"
        :modules="modules"
        :navigation="{
          prevEl: `#${componentId} [data-nav-prev]`,
          nextEl: `#${componentId} [data-nav-next]`,
        }"
        watch-slides-progress
        space-between="16"
        @swiper="setThumbsSwiper"
      >
        <SwiperSlide v-for="(image, i) in thumbImages" :key="image.url || i" class="image-gallery-zoomed__thumb">
          <VcImage
            :class="[
              'image-gallery-zoomed__thumb-img',
              {
                'image-gallery-zoomed__thumb-img--active': activeIndex === i,
              },
            ]"
            :src="image.url"
            :srcset="`${thumbImages4x[i].url} 4x, ${thumbImages3x[i].url} 3x, ${thumbImages2x[i].url} 2x, ${image.url} 1x`"
            :lazy="i < THUMBS_PER_VIEW ? false : true"
            tabindex="0"
            @keydown="handleKeyDownThumb($event, i)"
          />
        </SwiperSlide>
      </Swiper>
      <div class="image-gallery-zoomed__nav-buttons" :class="[{ 'opacity-0': !showSwiperNav }]">
        <VcNavButton :label="$t('common.buttons.previous')" size="xs" direction="left" data-nav-prev />
        <div class="image-gallery-zoomed__thumbs-counter text-base font-medium md:text-sm">
          {{ activeIndex + 1 }}/{{ thumbImages.length }}
        </div>
        <VcNavButton :label="$t('common.buttons.next')" size="xs" direction="right" data-nav-next />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useBreakpoints } from "@vueuse/core";
import { Pagination, Navigation, Thumbs, Zoom, Keyboard } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/vue";
import { ref, onMounted, computed, getCurrentInstance, watch, toRef, nextTick } from "vue";
import { BREAKPOINTS } from "@/core/constants";
import { transformImageUrls } from "@/shared/thorlabs/shared/image-resize";
import type { ImageType } from "@/core/api/graphql/types";
import type SwiperCore from "swiper";

const props = defineProps<{ currentIndex: number; images: ImageType[] }>();
const currentIndex = toRef(props, "currentIndex");

const componentId = `image-gallery-zoomed_${getCurrentInstance()!.uid}`;
const THUMBS_PER_VIEW = 5;
const DEFAULT_THUMB_SIZE = 66;
const breakpoints = useBreakpoints(BREAKPOINTS);
const isDesktop = breakpoints.greaterOrEqual("md");
const modules = [Pagination, Navigation, Thumbs, Zoom, Keyboard];
const activeIndex = ref(0);
const imagesSwiper = ref<SwiperCore | null>(null);
const isZoomed = ref(false);
const thumbsSwiper = ref<SwiperCore | null>(null);
const showSwiperNav = ref(false);
const hideZoomBtn = ref(false);

const setImagesSwiper = (swiper: SwiperCore) => {
  imagesSwiper.value = swiper;

  swiper.on("slideChange", function () {
    const newActiveIndex = swiper.activeIndex;
    const firstVisibleIndex = thumbsSwiper.value?.activeIndex;
    const lastVisibleIndex = (thumbsSwiper.value?.activeIndex ?? 0) + THUMBS_PER_VIEW - 1;

    if (newActiveIndex === lastVisibleIndex) {
      thumbsSwiper.value?.slideNext();
    } else if (newActiveIndex === firstVisibleIndex) {
      thumbsSwiper.value?.slidePrev();
    }

    activeIndex.value = newActiveIndex;
  });
};

const checkActiveImageSize = async (swiper: SwiperCore) => {
  if (!swiper) {
    return;
  }
  await nextTick();

  // Polling mechanism to wait for slides to be available
  const waitForSlides = () => {
    return new Promise<void>((resolve) => {
      const interval = setInterval(() => {
        if (swiper.slides.length > 0) {
          clearInterval(interval);
          resolve();
        }
      }, 100);
    });
  };

  await waitForSlides();

  const activeSlide = swiper.slides[activeIndex.value];
  if (!activeSlide) {
    return;
  }
  const imgElement = activeSlide.querySelector("img");
  if (imgElement) {
    const imgRect = imgElement.getBoundingClientRect();
    const containerRect = activeSlide.getBoundingClientRect();
    const isSmallerThanContainer = imgRect.width < containerRect.width;
    hideZoomBtn.value = isSmallerThanContainer ? true : false;
  }
};

const zoomOptions = {
  maxRatio: 8,
  minRatio: 1,
  limitToOriginalSize: true,
};

const setThumbsSwiper = (swiper: SwiperCore) => {
  thumbsSwiper.value = swiper;
};

const thumbImages = computed(() =>
  transformImageUrls(
    props.images as { url: string; name: string; description: string | undefined }[],
    DEFAULT_THUMB_SIZE,
    DEFAULT_THUMB_SIZE,
  ),
);
const thumbImages2x = computed(() =>
  transformImageUrls(
    props.images as { url: string; name: string; description: string | undefined }[],
    DEFAULT_THUMB_SIZE,
    DEFAULT_THUMB_SIZE,
    2,
  ),
);
const thumbImages3x = computed(() =>
  transformImageUrls(
    props.images as { url: string; name: string; description: string | undefined }[],
    DEFAULT_THUMB_SIZE,
    DEFAULT_THUMB_SIZE,
    3,
  ),
);
const thumbImages4x = computed(() =>
  transformImageUrls(
    props.images as { url: string; name: string; description: string | undefined }[],
    DEFAULT_THUMB_SIZE,
    DEFAULT_THUMB_SIZE,
    4,
  ),
);

const toggleZoom = () => {
  imagesSwiper.value?.zoom.toggle();
};

const handleToggleZoom = (event: KeyboardEvent) => {
  if (event.key === "Enter" || event.key === " ") {
    event.preventDefault();
    toggleZoom();
  }
};

const handleKeyDownThumb = (event: KeyboardEvent, index: number) => {
  if (event.key === "Enter" || event.key === " ") {
    imagesSwiper.value?.slideTo(index);
  }
};

onMounted(() => {
  imagesSwiper.value?.on("zoomChange", () => {
    const zoomed = (imagesSwiper.value?.zoom?.scale ?? 1) === 1;
    isZoomed.value = zoomed ? true : false;
  });

  if (thumbImages.value.length > THUMBS_PER_VIEW) {
    showSwiperNav.value = true;
  }
});

watch(currentIndex, () => {
  if (imagesSwiper.value) {
    imagesSwiper.value.slideTo(currentIndex.value);
  }
});
</script>

<style scoped lang="scss">
.image-gallery-zoomed {
  @apply select-none flex flex-wrap gap-4 md:max-w-[450px] max-w-full mx-auto;

  &__images-container {
    @apply relative aspect-square max-w-full self-start mb-10 mx-auto max-h-[350px] md:max-h-max;

    .swiper-slide {
      @apply cursor-zoom-in flex items-center justify-center;

      &.swiper-slide-zoomed {
        @apply cursor-move touch-none;
      }
    }
  }

  &__button {
    @apply absolute left-0 right-0 top-full mt-3 mx-auto flex items-center justify-center border-0 bg-transparent text-[--color-neutral-a1] z-[2] gap-1 font-medium;

    svg {
      @apply stroke-white stroke-1 scale-x-[-1] border rounded-full border-[--color-neutral-a4] w-[30px] h-[30px] text-[--color-neutral-a1] bg-white p-[6px];
    }

    &:hover {
      svg {
        @apply border-[--color-neutral-a2];
      }
    }

    &--hidden {
      @apply hidden;
    }
  }

  &__images {
    @apply w-full h-full bg-[--color-additional-50] rounded duration-300 ease-linear;
  }

  &__img {
    @apply relative rounded object-center object-contain text-[0];
  }

  &__img-caption {
    @apply absolute left-1 right-1 text-center m-auto bottom-1 px-3 py-[7px] text-[--color-neutral-a1] bg-[--color-white] border border-[--color-neutral-a4] rounded-2xl text-xs font-medium;
  }

  &__thumbs-container {
    @apply w-full flex justify-center items-center flex-row flex-wrap gap-2;
  }

  &__thumbs {
    @apply min-w-full;

    &-counter {
      @apply min-w-10 text-center pb-[2px];
    }
  }

  &__thumb {
    @apply p-[5.5px] aspect-square cursor-pointer -outline-offset-1 outline outline-1 outline-[--color-neutral-a4] rounded-[4px];

    &:hover {
      @apply outline-[--color-neutral-a3];
    }

    &.swiper-slide-thumb-active {
      @apply outline-[--color-neutral-a1];
    }
  }

  &__thumb-img {
    @apply w-full h-auto object-center object-contain text-[0];
  }

  &__nav-buttons {
    @apply flex justify-between items-center mt-2 gap-2;

    .vc-nav-button {
      @apply shadow-none bg-transparent text-[--color-neutral-a1] w-5 h-5;

      :deep(svg) {
        @apply stroke-white stroke-2 w-full h-full;
      }

      &:disabled,
      &--disabled {
        @apply text-[--color-neutral-a4];
      }
    }
  }
}
</style>
